import api from './axios'

export default {
  getCJMMedian: async () => {
    const { data } = await api.get('/stats/cjm/median')
    return data
  },

  getCJMTariffs: async () => {
    const { data } = await api.get('/stats/cjm/tariffs')
    return data
  },

  getProjectsCount: async () => {
    const { data } = await api.get('/stats/projects')
    return data
  },

  getProjectsTariffs: async () => {
    const { data } = await api.get('/stats/projects/tariffs')
    return data
  },

  getUsers: async () => {
    const { data } = await api.get('/stats/users')
    return data
  },

  getUsersCount: async () => {
    const { data } = await api.get('/stats/users/active')
    return data
  },

  getUsersTariffs: async () => {
    const { data } = await api.get('/stats/users/tariffs')
    return data
  },

  getUsersTariffsGrouped: async () => {
    const { data } = await api.get('/stats/users/tariffs/grouped')
    return data
  },

  login: idToken => {
    return api.post('/oauth/callback', { idToken })
  },

  restoreTrash: deleteUUID => {
    return api.post('/support/trash/restore', { deleteUUID })
  },

  transferSubscription: ({ fromUserId, toUserEmail }) => {
    return api.post('/support/users/transfer-subscription', { fromUserId, toUserEmail })
  },

  deleteTrialSubscription: ({ id }) => {
    return api.delete(`/support/subscriptions/${id}`)
  }
}
