import Vue from 'vue'
import VueRouter from 'vue-router'

import decodeToken from '@/utils/decodeToken'
import deleteToken from '@/utils/deleteToken'

import Home from '@/pages/Home'
import Login from '@/pages/Login'
import User from '@/pages/User'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/charts', name: 'Charts', redirect: '/charts/users' },
  {
    path: '/charts/projects',
    name: 'ChartsProjects',
    component: () => import(/* webpackChunkName: "charts" */ '@/pages/Charts/Projects'),
    meta: { permissions: ['PROJECTS'] },
  },
  {
    path: '/charts/projects/active',
    name: 'ChartsProjectsActive',
    component: () => import(/* webpackChunkName: "charts" */ '@/pages/Charts/ProjectsActive'),
    meta: { permissions: ['PROJECTS'] },
  },
  {
    path: '/charts/projects/tariffs',
    name: 'ChartsProjectsTariffs',
    component: () => import(/* webpackChunkName: "charts" */ '@/pages/Charts/ProjectsTariffs'),
    meta: { permissions: ['PROJECTS'] },
  },
  {
    path: '/charts/tools',
    name: 'ChartsTools',
    component: () => import(/* webpackChunkName: "charts" */ '@/pages/Charts/Tools'),
    meta: { permissions: ['CJM'] },
  },
  {
    path: '/charts/users',
    name: 'ChartsUsers',
    component: () => import(/* webpackChunkName: "charts" */ '@/pages/Charts/Users'),
    meta: { permissions: ['USERS'] },
  },
  {
    path: '/charts/users/active',
    name: 'ChartsUsersActive',
    component: () => import(/* webpackChunkName: "charts" */ '@/pages/Charts/UsersActive'),
  },
  {
    path: '/charts/users/paid-tariffs',
    name: 'ChartsUsersPaidTariffs',
    component: () => import(/* webpackChunkName: "charts" */ '@/pages/Charts/UsersPaidTariffs'),
  },
  {
    path: '/charts/users/tariffs-grouped',
    name: 'ChartsUsersTariffsGrouped',
    component: () => import(/* webpackChunkName: "charts" */ '@/pages/Charts/UsersTariffsGrouped'),
  },
  { path: '/charts/*', redirect: '/charts/users' },
  { path: '/login', name: 'Login', component: Login },
  { path: '/support', name: 'Support', redirect: '/support/users', meta: { permissions: ['SUPPORT_BROWSE'] } },
  {
    path: '/support/users',
    name: 'SupportUsers',
    component: () => import(/* webpackChunkName: "support" */ '@/pages/Support/Users'),
    meta: { permissions: ['SUPPORT_BROWSE'] },
  },
  {
    path: '/support/users/:id',
    name: 'SupportUser',
    component: () => import(/* webpackChunkName: "support" */ '@/pages/Support/User'),
  },
  {
    path: '/support/projects/:id',
    name: 'SupportProject',
    component: () => import(/* webpackChunkName: "support" */ '@/pages/Support/Project'),
  },
  {
    path: '/support/projects',
    name: 'SupportProjects',
    component: () => import(/* webpackChunkName: "support" */ '@/pages/Support/Projects'),
    meta: { permissions: ['SUPPORT_BROWSE'] },
  },
  { path: '/support/*', redirect: '/support/users' },
  { path: '/user', name: 'User', component: User },
  { path: '*', redirect: '/' },
  // component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
})

router.beforeResolve((to, from, next) => {
  const token = decodeToken()
  if (!token && to.name !== 'Login') {
    deleteToken()
    return next({ name: 'Login' })
  }

  const userPermissions = token?.permissions || []
  const routePermissions = to.meta.permissions || []
  const userHasAllPermissions = routePermissions
    .map(routePermission => userPermissions.includes(routePermission))
    .reduce((acc, cur) => acc && cur, true)

  if (userHasAllPermissions) {
    return next()
  }
  next({ name: 'Home' })
})

export default router
