<template>
  <div class="menu" v-if="notLoginPage">
    <div class="primary-menu">
      <floating-active />
      <div class="logo">
        <img src="http://coba.tools/storage/cache/product_logo/storage/p/logo/5b07d45a59d5b.png" />
      </div>
      <router-link v-for="item of filteredMenu" class="link" :to="{ name: item.name }" :key="item.name">
        <icon class="icon" :icon="item.icon" />
      </router-link>
    </div>

    <div class="secondary-menu">
      <div class="avatar">
        <img v-if="avatar" :src="avatar" />
      </div>
      <router-link v-for="item of subroutes" class="link" :to="{ name: item.name }" :key="item.name">
        <icon class="icon" :icon="item.icon" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import MENU from '@/const/menu'

import FloatingActive from '@/components/FloatingActive'

export default {
  name: 'Menu',
  components: {
    FloatingActive,
  },
  computed: {
    ...mapState('user', ['avatar', 'permissions']),
    subroutes() {
      return MENU.find(item => this.$route.name?.match(item.name))?.routes || []
    },
    notLoginPage() {
      return this.$route.name !== 'Login'
    },
    filteredMenu() {
      return MENU.filter(item => {
        const userPermissions = this.permissions || []
        const routePermissions = item.permissions || []
        const userHasAllPermissions = routePermissions
          .map(routePermission => userPermissions.includes(routePermission))
          .reduce((acc, cur) => acc && cur, true)

        return userHasAllPermissions
      })
    },
  },
  data() {
    return {
      MENU,
    }
  },
}
</script>

<style lang="sass" scoped>
.menu
  position: fixed
  z-index: 1
  left: 0
  top: 0
  display: flex
  flex-direction: row
  width: 144px
  height: 100vh

  .primary-menu,
  .secondary-menu
    display: flex
    flex-direction: column
    width: 50%

  .primary-menu
    background-color: #172132

    .logo
      padding: 8px
      margin-bottom: 8px

      img
        max-width: 100%
        max-height: 100%

    .link
      display: flex
      justify-content: center
      align-items: center
      height: 56px
      transition: background .6s

      &.router-link-active
        background: #243c59

    .icon
      fill: white

  .secondary-menu
    background-color: #fff

    .avatar
      margin-bottom: 8px
      padding: 16px

      img
        max-width: 100%
        max-height: 100%
        border-radius: 50%

    .link
      display: flex
      justify-content: center
      align-items: center
      height: 56px

      &.router-link-active
        .icon
          fill: #0d87ff

    .icon
      fill: #000
      transition: fill .3s

    .user
      img
        max-width: 100%
        max-height: 100%
</style>
